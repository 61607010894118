import { render, staticRenderFns } from "./talentRecruitment.vue?vue&type=template&id=2bd4e939&scoped=true&"
import script from "./talentRecruitment.vue?vue&type=script&lang=js&"
export * from "./talentRecruitment.vue?vue&type=script&lang=js&"
import style0 from "./talentRecruitment.vue?vue&type=style&index=0&id=2bd4e939&prod&lang=less&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "2bd4e939",
  null
  
)

export default component.exports